<template>
  <div class="pages">
    <img :src="oss+'logo.png'" class="logo"/>
    <div class="text">
      <p>官方售后</p>
      <p>自助退款（3-5个工作）</p>
    </div>
    <van-form @submit="onSubmit" :show-error-message="false" scroll-to-error>
      <van-field v-model="json.phone" type="tel" placeholder="请输入手机号" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]"/>
      <!-- <van-field v-model="json.code" placeholder="请输入验证码" :rules="[{ required: true, message: '请输入验证码' }]">
        <template #button>
          <van-button size="small" :color="count_down>0?'#999999':'#8357F3'" native-type="button" @click="getcode" :disabled="count_down>0?true:false">{{count_down==0?'发送验证码':('已发送'+count_down+'秒')}}</van-button>
        </template>
      </van-field> -->
      <div class="btn"><van-button round block native-type="submit" :loading="loading">申请退款</van-button></div>
    </van-form>

    <div class="ruleContent">
      <div class="h4">用户规则</div>
      <div class="content">
        <p>1、申请退款需要验证购买会员的手机号。</p>
        <p>2、申请后3-5个工作日可到账，请耐心等待。</p>
        <p>3、若已使用app会员权益，不支持退款。</p>
        <p>4、若有其他问题请联系在线客服。</p>
        <p>5、因部分用户存在异常刷单情况，平台对于退款申请 会有严格的风控排查，如您的退款申请被风控排查，请于6小时之后再申请退款。</p>
      </div>
    </div>

  </div>
</template>
<script>
  import {validatorPhone} from '@/utils/validata'
  import {GetCode} from '@/services/global'
  import {PostRefund} from './services/services'
  export default {
    name:'refund',
    components: {},
    data () {
      return {
        count_down:0,
        json:{
          phone:'',
          code:''
        },
        loading:false
      }
    },
    created(){

    },
    computed:{
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    mounted(){

    },
    methods:{
      validatorPhone,
      getcode(){
        if(this.json.phone==''){
          this.$toast('请输入手机号码')
          return false
        }

        this.count_down=60
        var time = setInterval(()=>{
          this.count_down--
          if(this.count_down == 0){
            clearInterval(time)
          }
        }, 1000)

        GetCode({phone:this.json.phone}).then(result => {
          if(result.data.code==0){
            this.$toast(result.data.msg)
          }
        })
      },
      onSubmit() {
        this.loading = true
        PostRefund(this.json).then(result => {
          this.loading = false
          if (result.data.code == 1) {
            this.$toast.success(result.data.msg)
            this.json={
              phone:'',
              code:''
            }
            this.count_down=0
          } else {
            this.$toast.fail(result.data.msg)
          }
        })
      }
    }
  }

</script>
<style scoped lang="less">
.pages{background-image:linear-gradient(to bottom,#7D65F6,#8b9af8);position:relative;margin-top:40px;padding:20px 20px 20px 20px;border-radius:10px 10px 0px 0px;min-height:calc(100vh - 90px);
  .logo{position:absolute;top:-30px;left:calc(50% - 30px);z-index:1;width:60px;}
  .text{text-align:center;color:#fff;font-size:20px;padding:20px 0px;
    p{padding:2px 0px;}
  }
  .van-form{border-radius:10px;background:#fff;padding:10px 15px;
    .van-cell{padding:0px;margin:15px 0px;
      &::after{border-bottom:none;}
      /deep/.van-field__body{border:1px solid #ddd;border-radius:8px;height:32px;padding:8px;}
    }

    .btn{margin-top:20px;
      .van-button{background-image:linear-gradient(to right,#A2EDFD,#7D65F6);color:#fff;
        .van-button__text{font-weight:700;}
      }
    }
  }
  .ruleContent{margin-top:20px;color:#fff;
    .h4{font-weight:700;font-size:16px;}
    .content{margin-top:10px;line-height:24px;}
  }

}
</style>
