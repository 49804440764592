import {
  getcode,config
} from './api'
import {request, METHOD} from '@/utils/request'
export async function GetCode(params) {
  return request(getcode, METHOD.GET,params)
}
export async function GetConfig(params) {
  return request(config, METHOD.GET,params)
}
